.pmy_dig {
  display: block;
  box-sizing: border-box;
  /* position: relative;
  margin: 0%; */
  max-width: 300px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  margin-bottom: 30px;
  /* margin-left: 30px; */
  border: 0px solid #dddddd;
  /* margin-bottom: 20px; */
}

.pmy_button a{text-decoration: none;
  color:white;}
.pmy_dig img {
  display: block;
  margin: 4px auto;
}
.pmy_dig:hover,
.pmy_dig:focus {
  box-shadow: 4px 4px 8px 5px rgba(0, 0, 0, 0.2),
    2px 6px 20px 4px rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s ease-in-out;
}
.pmy {
  width: 300px;
}
.pmy_main {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.pmy_img {
  max-width: 100%;
  height: 50%;
  /* position: absolute; */
}
.pmy_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pmy_head {
  top: 200px;
  max-width: 80%;
  font-family: sans-serif;
  font-size: 15px;
  padding: 5px;
  color: rgb(100, 104, 102);
  z-index: 5;
  /* position: relative; */
  background-color: white;
  font-weight: bold;
}
.pmy_descr {
  text-align: justify;
  top: 210px;
  /* position: relative; */
  background-color: #ffffff;
  padding: 10px 25px;
  font-size: 15px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  z-index: 5;
  color: rgb(137, 137, 138);
  height: auto;
}
.pmy_link button {
  /* position: absolute; */
  bottom: 20px;
  background-color: #59bcd4;
  border: none;
  color: white;
  padding: 13px 25px;
  text-align: center;
  font-size: 19px;
  left: 30%;
  transition-duration: 0.4s;
  margin: 4px 2px;
  cursor: pointer;
}
.pmy_link button:hover {
  background-color: #b2e2f8; /* Green */
  color: white;
}
@media (max-width: 780px) {
  .pmy_main {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pmy_dig {
    display: block;
    box-sizing: border-box;
    /* position: relative; */
    max-width: 400px;
    height: 450px;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    margin: 15px;
    border: 0px solid #dddddd;
    margin-bottom: 20px;
  }
  .pmy_img {
    /* max-width: 150%; */
    width: 400px;
    height: 50%;
    /* position: absolute; */
  }
}
@media (max-width: 500px) {
  .pmy_main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .pmy{width: 100%;}
  .pmy_dig {
    display: block;
    box-sizing: border-box;
    /* position: relative; */
    /* max-width: 300px; */
    min-height:450px;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    margin-bottom: 30px;
    border: 0px solid #dddddd;
    margin-bottom: 20px;
  }
  .pmy_img {
    /* max-width: 150%; */
    width: 400px;
    height: 50%;
    /* position: absolute; */
  }
  .pmy_link button {
    /* position: absolute; */
    bottom: 20px;
    background-color: #59bcd4;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    left: 30%;
    transition-duration: 0.4s;
    margin: 4px 2px;
    cursor: pointer;
  }
}
