.App {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-x: scroll; */
}

table {
  /* display: table; */
  border-collapse: collapse;
  width: 100%;
  height: 200px;
}

th {
  border: 2px solid black;
  color: red;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  font-family: Arial, Helvetica, sans-serif;
}

td {
  border: 2px solid black;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  /* max-width:100px; */
}
tr td:nth-child(1) {
  width: 50px;
}

tr td:nth-child(2) {
  text-align: left;
  width: 240px;
}

tr td:nth-child(3) {
  max-width: 500px;
}

tr td:nth-child(4) {
  padding: 10px 20px;
}

.tab {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}
.tab img {
  width: 100%;
  height: 100px;
}
.tables {
  margin: 25px;
}
