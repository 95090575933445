.uba_img img {
  width: 45%;
  /* height: 250px; */
  margin: 10px;
  border: 1px solid #000;
}
.uba_img {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* top: 600px; */
}

.uba {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  margin-top: 40px;
  background-color: rgb(255, 255, 255);
  /* margin-left: 200px; */
  /* margin: 2rem; */
  /* width: 400px; */
  min-height: 850px;
  align-items: flex-start;
}

.uba_head {
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
}
.uba_video {
  display: block;
  margin: auto;
}
.uba_body hr {
  border: 1.5px solid rgb(245, 157, 75);
  margin-top: 8px;
}
.uba_video {
  max-width: 100%;
  height: 300px;
  justify-content: center;
  margin-top: 10px;
}
.uba_main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.uba_dsc {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.uba_date {
  text-align: center;
}
.pmy-main_video iframe{
  width:60%;
  margin:auto;

}

@media (max-width: 780px) {
  .uba_main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .uba {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: auto;
    /* flex-wrap: nowrap; */
    padding: 10px 10px;
    margin-top: 40px;
    background-color: rgb(255, 255, 255);
    /* margin-left: 200px; */
    /* margin: 3rem; */
  }
  .uba_img img {
    /* width: 180px; */
    height: auto;
    margin: 4px 2px 7px 21px;
  }
  .uba_video {
    width: 95%;
    height: 300px;
    justify-content: center;
    margin-top: 10px;
  }
}
@media (max-width: 500px) {
  .uba_main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .uba {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 10px;
    margin-top: 40px;
    background-color: rgb(255, 255, 255);
    /* margin-left: 200px; */
    /* margin: 3rem; */
    /* width: 330px; */
  }
  .uba_video {
    width: 99%;
    height: 300px;
    justify-content: center;
    margin-top: 10px;
  }
  .uba_img img {
    /* width: 130px; */
    width: 85%;
    height: auto;
    /* height: 150px; */
    margin: 4px 2px 7px 21px;
  }
  .pmy-main_video iframe {
    width: 100%;
    margin: 0;
    height: 300px;
    justify-content: center;
    margin-top: 10px;
  }
}

.uba_description{
  text-align: justify;
  /* text-transform: uppercase; */
}