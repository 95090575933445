.pmy-main_body img {
  width: 33%;
  height:170px;
  /* max-height: 10%; */
  /* height: 200px; */
  margin: 15px;
  object-fit:scale-down;

  /* border: 1px solid; */
}
.iframe-uba{
  width:70%;
  margin:auto;
  /* height:350px; */
}
.pmy_main_header{
  height:auto;
}
.pmy-main_img {
  display: flex;
  row-gap: 10px;
  /* height:350px; */
  justify-content: center;
  /* column-gap:15px; */
  flex-wrap: wrap;
  margin: auto;
  align-items:center;
  align-content: center;
}
.pmy-main-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.pmy-main {
  width: 55%;
  position: relative;
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */
  padding: 10px 10px;
  /* margin: auto; */
  background-color: rgb(255, 255, 255);
  /* margin: 2rem; */
  /* width: 450px; */
  max-width: 100%;
  margin-bottom: 30px;
}
.pmy-main_head {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
}

.pmy-main_body hr {
  border: 1.5px solid rgb(245, 157, 75);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.pmy-main_video{
  margin:4px;
}
.pmy-main_video iframe{
  margin: auto;
  width: 65%;
  display: flex;
  justify-content: center;
  height: 300px;
  margin-bottom: 20px;
}

.pmy-main_main {
  display: flex;
  /* width:65%; */
  flex-direction: row;
  /* flex-wrap: nowrap; */
  justify-content: space-evenly;
}
.pmy-main_date {
  text-align: center;
  font-weight: lightbold;
  font-size:1.4rem;
  margin:auto;

}
.pmy-main_description{
  font-weight: lightbold;
  padding:4px;
  text-align: justify;
}

@media (max-width: 780px) {
  .pmy-main {
    /* position: relative; */
    position: relative;
    display: flex;
    width:65%;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 30px 20px;
    margin-top: 40px;
    background-color: rgb(255, 255, 255);
    /* margin-left: 200px; */
    /* margin: 3rem; */
    max-height: 100%;

  }
  .pmy-main_body {
    width: 100%;
  }

  .pmy-main_video iframe {
    width: 90%;
    /* height: 300px; */
    /* max-height: 100%; */
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    margin: auto;
  }
  .pmy-main_img img {
    /* margin: auto; */
    /* width: 190px; */
    /* width: 80%; */
    /* height: 150px; */
    /* margin: 5px 2px 7px 25px; */
  }
  .pmy-main_body hr {
    border: 1.5px solid rgb(245, 157, 75);
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
}
@media (max-width: 500px) {
  .pmy-main_main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .pmy-main {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 30px 20px;
    margin-top: 40px;
    background-color: rgb(255, 255, 255);
    /* margin-left: 200px; */
    /* margin: 3rem; */
    width: 330px;
  }
  .pmy-main_video iframe {
    width: 100%;
    margin: 0;
    height: 300px;
    justify-content: center;
    margin-top: 10px;
  }
  .pmy-main_img{
    height:auto;
  }
  .pmy-main_img img {
    width: 100%;
    /* height: 150px; */
    /* margin: 4px 2px 7px 21px; */
  }
  .iframe-uba{
    width:90%;
    margin:auto;
    height:fit-content;
  }
}
