.Struct_img{
    display:flex;
    flex-direction:column;
    justify-content:center;
    height:fit-content;
    align-items:center;
    text-justify: distribute-all-lines;
}
.card-img{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-gap:1.75rem;
}
 .Struct_img img{
     height: 900px;
     width: 100%;
     margin-top: 3%;
     margin-bottom: 3%;
 }
 @media (max-width:1000px){
     .card-img{
         display:block;
     }
 }
@media (max-width:500px){
    .card-img{
        display:block;
    }
    .Struct_img img{
        height: 600px;
        max-width: 100%;
        /* height: auto; */
    }

}
