@media screen and (max-width: 950px) {
  .card3 {
    grid-template-columns: auto;
  }
  .cert {
    width: 80%;
  }
  .carr {
    width: 100%;
  }
}
@media screen and (max-width: 600px){
  .event-card{
    width:65%;
  }
}

@media screen and (max-width: 500px) {
  .card1 {
    width: 100%;
    /* display:block; */
    padding: 0px;
    margin: 0px;

    /* margin:2px 4px; */
    /* grid-template-columns:100%; */
  }

  .bdc-container {
    overflow: scroll;
  }
  .sapling {
    height: fit-content !important;
  }
  .flexs {
    /* display: block; */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content !important;
  }
  .flexs img {
    /* margin-left:calc(center-10px); */
    justify-content: center;
    height: auto;
    width: 100%;
    border-radius: 0;
    /* margin-left: 25px; */
  }

  .card2 {
    grid-template-columns: auto;
    margin: 0px;
  }
  .disp {
    display: block;
  }
  .disp p {
    margin: 0 !important;
    padding: 7px !important;
  }
  iframe {
    width: 100%;
    margin: auto;
    height: 200px;
  }
  .card3 {
    margin: 0px;
    max-width: max-content;
    padding: 0px;
    width:100%;
  }
  .talent {
    grid-template-columns: auto;
    margin: calc(100%-width);

    /* width:max-content; */
  }
  .vid {
    display: block;
  }
  .card6 {
    display: block;
  }
  .card6 img {
    display: block;
    margin: 0px auto;
    /* width:50%; */
  }
  .displa {
    display: block;
  }
  .n {
    /* grid-template-columns: 0.4fr 2fr; */
    grid-template-columns: 0.5fr 1fr 1fr;
  }
  .np {
    margin: auto;
    width: 100%;
    display: block;
    text-align: center;
  }
  .carr {
    margin: 0px;
    padding: 0px;
  }

  .im img {
    width: 100%;
    /* padding: 4px; */
    /* height: 180px; */
  }

  .cardss {
    margin: 10px;
    width: 80%;
    display: block;
  }
  .slider-img {
    width: 95%;
    height: 45vh;
  }
  .floating-btn {
    font-size: 1rem;
  }

  .floating-btn svg {
    font-size: 1.8rem;
  }

  .event {
    width: 95%;
  }
  .slid {
    margin: 0;
  }
  .event .floating-btn {
    color: var(--secondary-font-light);
    top: 50%;
  }
  .event-info .event-name {
    font-size: 1.6rem;
  }
  /* .tables th{display:flex} */
  /* .tables td{display:flex;} */

  .tables tr td:nth-child(1),
  .tables tr td:nth-child(2),
  .tables tr td:nth-child(3) {
    width: 100%;
    text-align:center;
  }
  .tables tr td:nth-child(4) {
    width: 100%;
  }
  .mob {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .mob h4 {
    text-align: center;
    color: red;
    padding: 2px;
  }
  .nlm_video {
    margin: 0px;
    width: 100%;
  }

  /* changes */
  .cert {
    width: 100%;
  }
  .cert > * {
    width: 100%;
  }
  .contain {
    padding: 0;
  }
  .displa {
    margin: 7px;
    padding: 7px;
  }
  .pp{padding:0px}
  .pp p{padding-left:5px}
  .event-content p{padding:10px 0px;}
  .slider-img-main{
width:100%;}
.main-slide{
  width:100%;
}
.sub-slide{
  width:95%;
}
.card3 .c{
  width:100%;
}
.card7{
  display: block;
}
.report-activity-text{text-align: center;
  font-weight: 500;
padding:20px;}

.event-card{
  width:80%;
}
.slider-img-main{
  height:400px;
}
.iframe{
  height:175px;
  margin-left:20px;
  width:90%;
  margin-bottom: 20px;
}
.dis{
  margin:0px;
  margin-bottom:5px;
}

.vid img{
  width:100%;
}
.flex-items{width:100%;}

}
.activity{
  width:90%;
}

@media screen and (max-width: 380px) {
  .iframe{
  height:150px;
  margin:50px 0px 0px 10px;
  }
}