* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
x-icon{
  border:10px solid green;
}
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}
:root {
  --primary-color: rgb(11, 25, 71);
  --primary-font-dark: rgb(0, 0, 0);
  --secondary-font-light: rgb(255, 255, 255);
  --secondary-bg-dark: #edc905;
  --secondary-font-dark: #ff3447;
}
.link {
  color: var(--secondary-font-light);
  font-size: 1rem;
}
.link:hover {
  color: var(--secondary-bg-dark);
}

.header-container {
  background: var(--primary-color);
  color: var(--secondary-font-light);
}
.header {
  width: 90%;
  height: 75px;
  margin: auto;
  display: flex;
  align-items: center;

  justify-content: space-between;
  background: inherit;
  /* position: sticky; */
  top: 0;
}
.floating-menu-btn {
  display: none;
  z-index: 100;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-bg-dark);
  padding: 12px;
  border-radius: 15px;
}
.logo span {
  font-size: 1.9rem;
  font-weight:600;
  padding: 0px 7px;
}
.header-center {
  display: flex;
  align-items: center;
  background-color: inherit;
}
.menu {
  background-color: inherit;
}
.menu > ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.header ul li {
  text-transform: capitalize;
  list-style: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  font-size: 1rem;
}
.header ul li a {
  color: inherit;
  text-decoration: none;
}
.header-right span {
  padding: 0 10px;
}
.sub-menu {
  display: block;
  position: absolute;
  top: 75px;
  right: 20%;
  background-color: inherit;
  z-index: 10;
}

.header button {
  background-color: inherit;
  color: var(--secondary-font-light);
  border: 0;
  cursor: pointer;
  font-size: inherit;
  transition: all 0.2s ease-in;
}
.header ul li:hover,
.header button:hover {
  color: var(--secondary-bg-dark);
}
@media (max-width: 980px) {
  .header {
    width: 100%;
  }
  .logo {
    padding: 10px 5px;
  }
}
@media (max-width: 780px) {
  .header-container {
    background-color: var(--secondary-bg-dark);
    color: var(--primary-color);
  }
  .header {
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    position: relative;
  }
  .floating-menu-btn {
    display: block;
    position: absolute;
    font-size: 2rem;
    top: calc(45% - 1rem);
    right: 1rem;
    z-index: 1000;
  }
  .logo {
    flex: 7;
    justify-content: flex-start;
    background-color: inherit;
    padding-left: 30px;
  }

  .header-center,
  .header ul {
    /* width: 100%; */
    flex-direction: column;
    justify-content: flex-start;
  }
  .header-center {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: calc(100vh);
    overflow: scroll;
    text-align: center;
    padding: 10px 0;
    padding-top: 3rem;
    background-color: #fff;
    color: var(--primary-font-dark);
    z-index: 1000;
  }

  .header ul,
  .menu {
    width: 100%;
  }
  .header ul li {
    width: 100%;
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .sub-menu {
    position: static;
    display: flex;
  }
  .header-right {
    flex: 2;
    /* margin-right: 100px; */
  }
}

@media (max-width: 580px) {
  .header-right {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-size: 2rem;
    background-color: var(--primary-color);
    color: var(--secondary-font-light);
  }
  .floating-menu-btn {
    top: calc(25% - 1rem);
  }
}
