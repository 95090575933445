* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
/* hr {
  height: 2px;
  background-color: #000;
  display: block;
  margin: 5px;
} */

.font-lg {
  font-size: 1.8rem !important;
}
.font-md {
  font-size: 1.3rem !important;
}
.font-sm {
  font-size: 1rem !important;
}

.card {
  box-sizing: border-box;
  /* background-image: brown; */
  /* background-position: absolute; */
  /* border:4px solid blue; */
}
.content-overflow {
  max-height: 150px;
  overflow: hidden;
}
.box-container {
  padding: 10px;
  text-align: justify;
}
.box-content {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 12px 0;
}
.box-title {
  margin: 10px 0;
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: capitalize;
}
.flexs img {
  padding: 10px;
  margin: 10px;
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 50%;
}
.card1 {
  width: fit-content;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
  padding: 10px;
  /* background-color: chartreuse; */
  margin: 0px auto;
}
.container {
  /* position:relative; */
  background-image: url("../image/bgnss.jpg");
  /* background-color:blueviolet; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  opacity: 2;
  background-clip: padding-box;
  object-fit: cover;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin: 0px 0px;
  padding: 20px 10px;
}
.c {
  display: flex;
  flex-wrap: wrap !important;
  /* justify-content: space-evenly; */
  background-color: white;
  padding: 15px;
}
.seminar.c {
  display: flex !important;
  /* background: none; */
}
.seminar.card2{max-width: 700px;;}
.c2 {
  flex-direction: column;
}
.sapling {
  padding: 10px 20px;
  flex-direction: column;
}
.card-main {
  flex-direction: column;
}
.flexs {
  display: flex;
  /* justify-content:center; */
  align-items: center;
  height: fit-content;
  padding: 5px;
  /* margin:19px; */
  width: 480px;
}
.disp {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: auto;
  /* align-items: stretch; */
  align-content: flex-end;
  /* margin:10px; */
  padding: 10px;
  /* font-size: 1.2rem; */
  /* text-align: center; */
  /* width:450px; */
}
.disp p {
  /* padding:10px; */
  margin: 7px;
}
.you-tube-video{
  /* width:30%; */
  height:100px;
  /* margin: 122px;; */
}
.iframe {

 
  margin: 10px;
 margin-left:50px;
  width: 80%;
  height: 200px;
}

.talent video {
  width: 300px;
  height: 300px;
}
.grid1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  align-items: center;
  background-color: white;
  width: 300px;
  /* text-justify: distribute-all-lines; */
}
.grid1 img {
  height: 180px;
  width: 100%;
  /* border: 1px solid; */
  object-fit: contain;
  /* width: 100%; */
}
.grid1 p {
  padding: 15px;
  text-align: justify;
}
.card2 {
  max-width: 800px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 2rem;
  padding: 10px;
  /* background-color: chartreuse; */
  margin: 0px auto;
}

.talent {
  width: max-content;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  justify-content: center;
  margin: auto;
}

.talent img {
  padding: 7px;
  width:250px;
  height:250px;
  object-fit:scale-down;
}

.vid img{
  padding: 7px;
  /* width:250px; */
  height:250px;
  object-fit:scale-down;
}

.card3 {

width:100%;  
  width: calc((fit-content)-20%);
  display: flex;
  /* grid-template-columns: auto auto; */
  flex-wrap:wrap;
  /* grid-gap: 2rem; */
  padding: 10px;
  /* background-color: chartreuse; */
  margin: auto;
  column-gap: 100px;
  justify-content:center;
  row-gap:50px;
  flex-grow: 1;
  
}

.height{
  padding:auto;
  height:125px;
  justify-content: center;
  align-items:center;
  margin:auto;
  text-align: center;
  
}
.card3 .c{
  width:500px;
  /* height:500px; */
}

.dis {
  margin: 10px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  height:auto;
}


.vid video {
  width: 100%;
  height: 200px;
  padding: 15px;
}

.vid {
  padding: 10px;
  margin: auto;
  justify-content: center;
}

.niii {
  padding: 10px;
  text-align: center;
}
.nith {
  position: relative;
  /* border: 2px solid black; */
  margin: 0 !important;
  width: 100% !important;
  background-color: var(--secondary-bg-dark);
  background-image: url("https://static.wixstatic.com/media/a34e7bdfff2b4ba0b0621eb058d62656.png/v1/crop/x_0,y_0,w_500,h_500,q_85/a34e7bdfff2b4ba0b0621eb058d62656.webp");
}
.nith .floating-btn {
  /* color: var(--secondary-font-light); */
  color:black;
}
.slid {
  /* background-image: url("https://static.wixstatic.com/media/7a80d103056d491895f4d6be5be211ef.jpg/v1/fill/w_1225,h_488,al_c,q_85,usm_0.66_1.00_0.01/7a80d103056d491895f4d6be5be211ef.jpg"); */
  background-size: cover;
  /* padding: 100px; */
  margin: 20px;
  height: 300px;
  border: 2px solid black;
}
.floating-btn {
  margin: 10px;
  position: absolute;
  top: 45%;
  cursor: pointer;
}
.floating-btn svg {
  font-size: 3rem;
}
.floating-btn.btn-left {
  left: 5%;
}
.floating-btn.btn-right {
  right: 5%;
}
.slider-img-main{
  width: 80%;
  height: 650px;
  display: block;
  margin: auto;
  object-fit: cover;
  object-position: center;
}
.image-description{
  color:green;
  border:2px dotted black;
  background-color:azure;
  /* text-transform: uppercase; */
  padding:5px;
  margin:auto;
  /* width:75%; */
  /* position:absolute; */

  text-align:center;
  /* top:40%; */
  margin:auto;
  /* left:auto; */
  /* display:none; */
  /* width:100%; */
  z-index:5;
  font-size:1.4rem;
  font-weight: lightbold;
  
  
}

.main-slide{
  width:80%;
}
.sub-slide{
  width:75%;
}
/* .nith:hover .image-description{
  display:block;
   filter:blur(2px)
} */
/* .nith:hover .imagesss{
  filter:blur(5px)
}  */

.nith > div {
  width: 100% !important;
}
/* .nithiesh:hover .image-description{
  display:block;
}
.nithiesh:hover .imagesss{
  filter:blur(5px)
} */
.event {
  width: 75%;

  margin: auto;
  /* background-color: yellow; */

  padding: 40px 0;
}
.slid h1 {
  color: white;
}
.card23 {
  max-width: 700px;
  display: grid;
  grid-template-rows: auto;
  /* grid-template-columns: 1fr 3fr 1fr; */
  grid-gap: 2rem;
  padding: 10px;
  /* background-color: chartreuse; */
  margin: 0px auto;
}
.carddd {
  background-color: lightgray;
  border: 2px solid grey;
}
.carddd h2 {
  text-align: center;
  padding: 6px;
}
.carddd p {
  text-align: justify;
  padding: 5px;
  margin: 4px;
}
.n {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
}
.np {
  padding: 10px 0px;
  text-align:center;
}
.np p{text-align:center}
.pp {
  /* margin:auto; */
  /* width:fit-content; */
  max-width: 250px;
  padding-left:20px;
}
.pp p {
  padding: 5px;
  padding-left:25px;
  text-align: justify;
  /* text-align:center; */
}
.contain {
  margin: auto;
  padding: 20px;
  min-width: fit-content;
}

.contain h1 {
  padding: 3px;
  margin: 5px;
  text-align: center;
}
.activity{
  width:45%;
  height:100px;
}
.card6 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  min-width: auto;
justify-content: flex-start;
align-items: flex-start;
align-content: flex-start;
  max-width: 750px;
  min-height: 85vh;
  padding:20px;
  text-align:center;
}
.displa {
  display: flex;
  flex-direction: row;
    flex-wrap: wrap;
  padding: 10px;
  margin: 14px;
  margin-left:50px;
  column-gap: 10px;
  row-gap:10px;
  text-align: center;
  /* justify-content: center; */
}
.flex-items{
  width:30%;
}
.card6 h1 {
  margin: 0px auto;
  text-align: center;
}
.displa a {
  padding: 11px;
}

.card7 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: auto;
  min-width: auto;

  max-width: 700px;
  min-height: 85vh;
}
.im img {
  width: 100%;
  height: 225px;
  border: 1px solid black;
  object-fit: cover;
}

.carr {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  margin: auto;
  /* max-width: 80%; */
  /* column-gap: 15px; */
  justify-content: center;
  /* align-items: flex-start; */
}
.niw {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 80%; */
  justify-content: center;
  /* align-items: flex-start; */
}
.cardss {
  flex-grow: 1;

  /* margin:12px; */
  /* flex:0 0 0 250px; */
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 300px;
  background-color: white;
  margin: 10px;
}
.cert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1050px;
  margin: 20vh auto;
  /* margin-left:400px; */
  /* height: 35vh; */
  height: 250px;
  width: 60%;
}
.cert > * {
  /* width: 70%; */
  padding: 10px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  text-align: center;
}
.cert h3,
.cert h4 {
  padding: 40px;
  margin: auto;
  text-align: center;
}

.slider-img {
  width: 75%;
  height: 650px;
  display: block;
  margin: auto;
  object-fit: cover;
  object-position: center;
}

.event-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--secondary-font-light);
}

.event-info .event-name {
  font-size: 2.5rem;
  font-weight: 700;
  flex-wrap: wrap;
  text-align: center;
}

.event-info .event-date {
  font-size: 1.8rem;
  font-weight: 500;
}

.tables {
  /* margin: 25px; */
}
thead th {
  border: 2px solid black;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.tables tr td {
  border: 2px solid black;
  padding: 4px;
}
.td {
  width: 33%;
  text-align: center;
}

/* .tables tr td:nth-child(1) {
  width: 10%;
}
.tables tr td:nth-child(2) {
  width: 20%;
}

.tables tr td:nth-child(3) {
  width: 20%;
}

.tables tr td:nth-child(4) {
  width: 35%;
} */
.tables img {
  /* width: 100%; */
  height: 200px;
}

tr {
  text-align: center;
}
.mob {
  display: none;
  /* display:flex; */
  position: sticky;
  top: 0;
  z-index: 100;
}
.text {
  color: red;
}


.event-card .c{
  padding:10px;
}
.event-card{
  /* background-color: white; */
  width:60%;
  margin:auto;
  display:grid;
  grid-template-columns: auto;
  /* grid-gap:2rem; */
  /* height:550px; */

}
.event-title{
  /* padding:5px; */
  margin:auto;
  display:flex;
  /* background-color:black; */
}
.event-content img{
  width:60px;
  height:50px;
}
.event-title a{
  color:black;
  text-decoration:none;
}
.event-content p{
  text-align:center;
  text-align: justify;
  font-size: 1.5rem;
  height:20px;
  padding:6px 0px;
}
.event-content{
  display:flex;
}
.horizontal hr{
  margin:15px 0px;
  border:1px dotted black;
}
.image-logos{
  width:30%;
}
.report-activity-text{
  color:black;
  font-weight: medium;
  font-size:1.5rem;
  padding:6px;
}
.height p{margin:auto;
padding:10px;}
.height h3{margin:6px 10px;}