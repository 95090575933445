.home-header-container,
.main-section-container,
.contact-container,
.footer-container {
  width: 100%;
  background-color: var(--secondary-font-light);
}
.loading-error {
  width: fit-content;
  margin: auto;
  color: orange;
}
.tagline {
  font-family: algerian;
}
.company-name,
.highlight {
  color: var(--secondary-font-dark);
}
.title {
  color: #1527cf;
}
.home-header,
.main-section,
.contact-main-container,
.footer {
  width: 75%;
  margin: auto;
}
.footer {
  padding: 15px 0;
  text-align: center;
}
.card-bg {
  width: 100%;
  height: 300px;
  background-color: var(--secondary-bg-dark);
  background-image: url("https://static.wixstatic.com/media/a34e7bdfff2b4ba0b0621eb058d62656.png/v1/crop/x_0,y_0,w_500,h_500,q_85/a34e7bdfff2b4ba0b0621eb058d62656.webphttps://static.wixstatic.com/media/a34e7bdfff2b4ba0b0621eb058d62656.png/v1/crop/x_0,y_0,w_500,h_500,q_85/a34e7bdfff2b4ba0b0621eb058d62656.webp");
}
.cards-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.cards-main {
  width: 100%;
  border-right: 3px solid #fff;
  text-align: center;
}
.home-header-top a img {
  /* padding-left: 15px; */
}
.img-container {
  width: 160px;
}
.img-container img {
  width: 100%;
  object-fit: contain;
}
/* .home-header-top  img{ */
/* width:300px; */
/* padding:10px; */
/* height:250px; */
/* } */

.cards-title,
.card-text {
  padding: 16px;
}
.cards-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--secondary-font-light);
}
.cards-text {
  /* width: 45%; */
  margin: auto;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 500;
}
.cards-main:last-child {
  border: none;
}
.home-container {
  background: var(--secondary-font-light);
}
.heading {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 15px 0;
  padding-top: 50px;
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-bottom: 4px solid;
}
.sub-heading {
  text-transform: uppercase;
  font-weight: 900;
  padding: 15px 0;
  font-size: 1.4rem;
  text-align: center;
}
/* .home-header-container {
  min-height: 70vh;
} */
.home-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.home-header-top span {
  font-size: 1.675rem;
  display: block;
  font-weight: 900;
  text-align: center;
  /* letter-spacing: 0.4rem; */
  /* letter-spacing: 1.01px; */
}

.home-header-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: -20px;
}
.main-section-symbol-img{
  width:25%;
}
.home-header-main .section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}
.home-header-main .section-1 .title {
  font-size: 1.75rem;
  padding: 6px 3px;
  font-weight: 700;
}
.home-header-main .section-1 .tagline {
  font-size: 1.5rem;
  padding: 6px 3px;
  font-weight: 500;
}

.home-header-main marquee {
  color: purple;
  margin: 20px 0;
}

.home-header-main .section-2 {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: rgb(92, 112, 228);
  font-size: 1.3rem;
  color: var(--secondary-font-light);
  margin-bottom: 40px;
  padding: 10px;
}
.home-header-main .section-2 div {
  width: 100%;
  display: flex;
  align-items: center;
}
.home-header-main .section-2 div:nth-child(odd) {
  justify-content: space-evenly;
}
.home-header-main .section-2 div:nth-child(even) {
  justify-content: center;
}
.home-header-main .section-2 div:nth-child(even) span {
  padding: 5px 10px;
}

/* Main Section */
.main-section p,
.main-section ul li {
  line-height: 1.5;
}
.main-section p {
  text-align: justify;
}
.main-section p.content::first-letter {
  margin-left: 3rem;
}
.main-section ul li {
  margin-left: 2.5rem;
}
.main-section img {
  display: block;
  margin: 10px auto;
}
.main-section div {
  padding-bottom: 15px;
}

.service-container {
  margin-bottom: 50px;
}
.contact-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
}
.contact-main > * {
  width: 100%;
}
.contact-details-main {
  width: 85%;
  margin: auto;
}
.contact-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.contact-details p {
  padding: 10px;
  font-size: 1.05rem;
  line-height: 1.4;
}
.faculty-list-container {
  width: 80%;
  margin: auto;
}
.faculty-grid-box {
  /* width: 80%; */
  background-color: rgb(205, 220, 230);
  /* background-color: var(--primary-font-dark); */
  /* color: var(--secondary-font-light); */
  color: black;
  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 30px;
}
.faculty-grid-item {
  width: 100%;
  height: 175px;
  display: flex;
  justify-content: space-evenly;
  margin: 20px auto;
}
.faculty-info {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  justify-content: space-evenly;
  text-transform: uppercase;
}
.faculty-grid-item img {
  width: 160px;
  object-fit: cover;
  object-position: center;
  height: 160px;
}
.faculty-info .faculty-name {
  font-size: 1.25rem;
}
.faculty-info .faculty-desgination {
  display: block;
  font-size: 1.05rem;
}
.faculty-info .faculty-desgination::before {
  display: block;
  content: "";
  width: 30px;
  position: relative;
  top: -12px;
  height: 3px;
  background-color: black;
}

.feedback-form {
  width: 75%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.feedback-form input,
.feedback-form textarea {
  width: 100% !important;
  padding: 10px;
  margin: 10px 0;
}
.feedback-form button {
  width: fit-content;
  margin: 10px 0;
  margin-left: auto;
  padding: 10px 30px;
  background-color: var(--primary-font-dark);
  color: var(--secondary-bg-dark);
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.5s ease-in;
}
.feedback-form button:hover {
  background-color: var(--secondary-bg-dark);
  color: var(--primary-font-dark);
}
.feedback-form textarea {
  height: 100px;
  resize: none;
}
@media (max-width: 980px) {
  .home-header {
    width: 100%;
  }
  .home-header-top span {
    /* flex-direction: column; */
    font-size: 1.6rem;
    text-align: center;
  }

  .home-header,
  .main-section,
  .contact-main-container,
  .footer {
    width: 90%;
  }
  .faculty-grid-box {
    width: 90%;
    /* flex: 0; */
  }
  .contact-details {
    width: 75%;
    /* flex: 1; */
    margin-left: 30px;
  }
  .contact-main {
    width: 80%;
    margin: auto;
    flex-direction: column;
  }
  .contact-details {
    width: 100%;
    margin: 0;
  }
  .feedback-form {
    width: 90%;
    margin: auto;
  }
  .feedback-form button {
    margin: 10px auto;
    width: 100%;
  }
}

@media (max-width: 580px) {
  html {
    font-size: 12px;
  }
  .home-header,
  .main-section,
  .contact-main-container,
  .footer {
    width: 95%;
  }
  .cards-container {
    flex-direction: column;
    height: fit-content;
    padding: 10px 0;
  }
  .cards-main {
    width: 85%;
    border: none;
    border-bottom: 3px solid #fff;
    padding: 15px;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .home-header-top {
    padding: 15px 0;
  }
  .home-header-top img {
    height: 70px;
  }
  .home-header-top span {
    font-size: 1.8rem;
    font-weight: 900;
  }
  .logo {
    flex: 0;
    justify-content: flex-start;
    padding: 10px;
  }
  .header-right {
    width: 100%;
    flex: 0;
  }
  .home-header-main .section-1 .title {
    font-size: 1.7rem;
    font-weight: 700;
    margin-top: 20px;
  }

  .home-header-main .section-1 .tagline {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .home-header-main .section-2 {
    font-size: 0.95rem;
  }
  .home-header-main .section-2 p {
    padding: 5px 10px;
    text-align: justify;
    font-size: 1.1rem;
  }
  .contact-main {
    width: 100%;
    flex-direction: column;
  }
  .main-section img {
    /* width: 90%; */
    object-fit: cover;
  }
  .contact-main > * {
    width: 100%;
  }
  .faculty-grid-box {
    width: 100%;
    margin: 0;
    padding: 0px;
  }
  .faculty-grid-item {
    height: 150px;
  }
  .faculty-grid-item img {
    height: 150px;
    width: 43%;
  }
  .faculty-info {
    width: 46%;
  }
  .home-header-top img {
    width: 75px;
    height: auto;
  }
  .img-container {
    width: 80px;
  }
  .sub-heading{
    font-size:2rem;
  }
  .main-section-symbol-img{
    width:30%;
  }
  
}
